// import wovon aus welcher Library oder Ordner
import React, { StrictMode } from "react"

//import ( StrictMode )
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import * as ReactDomClient from "react-dom/client";

import "bootstrap/dist/css/bootstrap.min.css" //Rechtsklick auf Dateinamen copy relative path
import "./index.css";


//import App from "./App_landing";
//import App from "./App";

import App from "./PresseWebShop";



//const element = <h1>Hello World!</h1>; //keine Anfuehrungszeichen da Pseudo Html

const root = ReactDomClient.createRoot(document.getElementById("root"));

root.render(<App></App>);
