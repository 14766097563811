import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class Footer extends Component {
    state = {  } 
    render() { 
        return  <div className="footer">
                    <hr className='hrUmb'/>
                    <div className='footerElement'>
                        Impressum
                    </div>    
                    <div className='footerElement'>
                        Kontakt
                    </div >  
                    <div className='footerElement'>
                        AGB
                    </div>  
                    <div className='footerElement'>
                        Datenschutz
                    </div>  
                
                </div>
                
    }
}
 








export default Footer;