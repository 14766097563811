import React, { Component, useState } from 'react';

import { BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom';

import Header from './components/header';
import Navbar from './components/navbar';

import Cards from './components/cards';
import Login from './components/login';

import Dashboard from './components/dashboard';
import ResultGrid from './components/searchResultGrid';
import ProductDetails from './components/detailanzeige';
import Merkliste from './components/merkliste';
import Warenkorb from './components/warenkorb';

import Footer from './components/footer';

const KartenKram = [
  { id: 1, titel: 'Literarischer Nacktmullkalender',  isbn: 123321123321223, cover: 'https://medien.umbreitkatalog.de/bildzentrale/978/386/265/1078.jpg', preis: '29,90' },
  { id: 2, titel: 'Literarischer Opossumkalender', isbn: 4565456545665, cover: '/assets/img/possum.jpg', preis: '19,90' },
  { id: 3, titel: 'Literarischer Schabenkalender', isbn: 7898788987789, cover: '/assets/img/moewe.jpg', preis: '39,90' },
  { id: 4, titel: 'Literarischer Iltiskalender', isbn: 7898788987789, cover: '/assets/img/iltis.jpg', preis: '9,90' },
  { id: 5, titel: 'Literarischer Katzenkalender', isbn: 7898788987789, cover: '/assets/img/loewe.jpg', preis: '29,90' },
  { id: 6, titel: 'Literarischer Lachmöwenkalender', isbn: 7898788987789, cover: 'https://medien.umbreitkatalog.de/bildzentrale/978/386/265/1078.jpg', preis: '15,90' },
  { id: 7, titel: 'Literarischer Kakerlakenkalender', isbn: 7898788987789 , cover: '/assets/img/kalender.jpg', preis: '65,90'},
]





class App extends Component {

  

  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      BuchKram:  [
        { id: 1, titel: 'Agility', untertitel: 'Trainingsaufbau', autor: 'Roth, Alexandra', verlag: 'Müller Rüschlikon Verlag', meldetext: 'lieferbar', isbn: 123321123321223, cover: 'https://medien.umbreitkatalog.de/bildzentrale/978/327/502/1871.jpg', thumb: 'https://medien.umbreitkatalog.de/bildzentrale_th/978/327/502/1871.jpg' , zusatzcover: ['https://medien.umbreitkatalog.de/bildzentrale/978/394/266/5230.jpg', 'https://medien.umbreitkatalog.de/bildzentrale/978/394/266/5230.jpg'], preis: '29,90€', einband:'GEB',  art:'TB', meldenummer:'00', artikelnummer:'1', auflage: '5. Auflage', themen: ['Bohnen', ' Fäuste'] , kurztext: 'Lorem Ipsum Bohnum' },
        { id: 2, titel: 'Ju-Jutsu Straßenkampftechniken', untertitel: 'Realistische Technikkombinationen für die Selbstverteidigung', autor: 'Wahle, Stefan', verlag: 'Books on Demand', meldetext: 'vergriffen', isbn: 4565456545665, cover: 'https://medien.umbreitkatalog.de/bildzentrale/978/373/862/7107.jpg', thumb:'https://medien.umbreitkatalog.de/bildzentrale_th/978/373/862/7107.jpg', zusatzcover: [], preis: '19,90€', einband:'GEB', art:'TB', meldenummer:'07', artikelnummer:'2', auflage: '3. Auflage' },
        { id: 3, titel: 'Terence Hill', untertitel: 'Die exklusive Biografie', verlag: 'Riva Verlag', autor: 'Lüdeke, Ulf', meldetext: 'Noch nicht erschienen', isbn: 789870009, thumb: 'https://medien.umbreitkatalog.de/bildzentrale_th/978/386/883/2037.jpg', cover: 'https://medien.umbreitkatalog.de/bildzentrale/978/386/883/2037.jpg', zusatzcover: [],  preis: '39,90€', einband:'GEB', art:'TB', meldenummer:'04', artikelnummer:'3', auflage: '2. Auflage' },
        { id: 4, titel: 'Buddy haut den Lukas', autor: 'Bud Spencer', untertitel: 'Regie: Michele Lupo, I 1980, FSK ab 6, DVD-Video, Dt, UT: Dt', verlag: 'LEONINE Distribution GmbH', meldetext: 'lieferbar', isbn: 7898788987345, thumb:'https://medien.umbreitkatalog.de/bildzentrale_th/088/765/426/9096.jpg', cover: 'https://medien.umbreitkatalog.de/bildzentrale/088/765/426/9096.jpg', zusatzcover: [], preis: '9,90€', einband:'GEB', art:'TB', meldenummer:'00', artikelnummer:'4', auflage: '2. überarbeitete Auflage' },
        { id: 5, titel: 'Die Edda des Snorri Sturluson', untertitel: '', verlag: 'Reclam', autor: 'Arnulf Krause',  meldetext: 'vergriffen', meldenummer:'07', isbn: 7898788987789, thumb:'https://medien.umbreitkatalog.de/bildzentrale_th/978/315/000/7822.jpg', cover: 'https://medien.umbreitkatalog.de/bildzentrale/978/315/000/7822.jpg', zusatzcover: [], preis: '29,90€', auflage: ''},
        { id: 6, titel: 'E-Gitarrenbau', untertitel: '', verlag: 'Verlag Martin Koch', autor: 'Koch, Martin', meldetext: 'lieferbar', isbn: 7123788987789, thumb: 'https://medien.umbreitkatalog.de/bildzentrale_th/978/390/131/4155.png', cover: 'https://medien.umbreitkatalog.de/bildzentrale/978/390/131/4155.png', zusatzcover: [], preis: '15,90€', einband:'GEB', art:'TB', meldenummer:'00', artikelnummer:'5', auflage: '2. Auflage'},
        { id: 7, titel: 'Phex', untertitel: 'Hilf Dir selbst dann hilft Dir Phex!', autor: 'Michko, Linda de/Demirtel, Eevie', verlag: 'Ulisses Spiele GmbH', isbn: 789878866989, meldetext: 'lieferbar', thumb: 'https://medien.umbreitkatalog.de/bildzentrale_th/978/396/331/1918.jpg', cover: 'https://medien.umbreitkatalog.de/bildzentrale/978/396/331/1918.jpg', zusatzcover: [], preis: '65,90€', einband:'GEB', art:'TB', meldenummer:'00', artikelnummer:'6', auflage: '1. Auflage'},
     ]
    };
  }

  handleLogin = () => {
    // Simulate a simple login check (replace with actual authentication logic)
    this.setState({
      loggedIn: true,
     
    });
  };

  handleLogout = () => {
    this.setState({
      loggedIn: false,
    });
  };

 //Manueller Login für Tests

 manualLogin = () => {
  this.setState({
    loggedIn: true,
  })
 }


//Warenkorb

state = { 
  //leeres Array zum befüllen

  items: [],
  merkliste: [
  ]
} 

// react oft => Vorteil: leichter zu "this"

addItem = (amount, name, price) => {

  //alten State holen (aktuelle Items)

  let currentItems = this.state.items; 

  

  //debugger; 

  //check, ob ein Item mit dem Namen(ID) bereits im Warenkorb vorhanden ist
  let existingItem = this.state.items.find(item => item.name == name);

  //item schon drin? - amount erhöhen

  if(existingItem) {
      existingItem.amount++;
  } else {
       currentItems.push ({
          amount, 
          name, 
          price
      });
  }
  //setState updated und führt render() neu aus
  this.setState({items: currentItems});

  console.log(this.state);
}
 

render() {

  const {loggedIn} = this.state;

  return (
    <Router>
      <div className="wrapper">
        <Header
          loggedIn={this.state.loggedIn}
          onLogin={this.handleLogin}
          onLogout={this.handleLogout}
        />
        <Navbar 
          loggedIn={this.state.loggedIn}
          onLogin={this.handleLogin}
          onLogout={this.handleLogout}
        />

        {/*<Dashboard  loggedIn={this.state.loggedIn} />*/}

        {!loggedIn && 
        <div className="main-container">
                    
                    <div className="product-container">
                        <Cards loggedIn={this.state.loggedIn} onAdd={() => this.addItem(1, 'Weg ist das Fischbrötchen', 29.90)} title="Bud Spencer - Mein Leben, meine Filme" image="https://medien.umbreitkatalog.de/bildzentrale/978/394/266/5216.jpg" description="Spencer, Bud/de Luca, Lorenzo/de Filippi, David" preview="Der aus zahlreichen Kultfilmen bekannte Bud Spencer ist für viele seiner Fans der Held ihrer Kindheit und eine Vaterfigur, die sie bis heute vergöttern."/>
                        <Cards onAdd={() => this.addItem(1, 'Katzenkalender', 350.00)} title="Terence Hill & Bud Spencer - Box I" image="https://medien.umbreitkatalog.de/bildzentrale/402/062/893/9441.jpg" description=" Auch die Engel essen Bohnen/Mr.Billion/Verflucht, verdammt und Halleluja"/>
                        <Cards onAdd={() => this.addItem(1, 'Kalender', 35.99)} title="Bud Spencer - In achtzig Jahren um die Welt" image="https://medien.umbreitkatalog.de/bildzentrale/978/386/265/1078.jpg" description="Spencer, Bud/de Luca, Lorenzo" preview="Dass nicht nur auf der Leinwand nichts und niemand diesen Mann aufhalten kann, sondern auch im wirklichen Leben, ist in Bud Spencers erstem Buch Mein Leben, meine Filme deutlich geworden, in dem er schon viele Details aus seinem ereignisreichen Leben verraten hat."/>
                        <Cards onAdd={() => this.addItem(1, 'Buch', 29.90)} title="Bud Spencer - Ich esse, also bin ich" image="https://medien.umbreitkatalog.de/bildzentrale/978/394/266/5230.jpg" description="Spencer, Bud/Luca, Lorenzo De" preview="In ICH ESSE, ALSO BIN ICH macht Kultstar und Bestsellerautor Bud Spencer eine seiner größten Leidenschaften - das Essen - zum Mittelpunkt des Seins. Diese These gilt es nun vor 12 großen Denkern, die ihn in Form von Halluzinationen heimsuchen, zu verteidigen."/>
                    </div>
                   
                    {/*<ShoppingCart items={this.state.items}/>*/}
                    
        </div>
        }


        <Routes >
            <>
              <Route
                  path="/dashboard"
                  element={loggedIn ? <Dashboard/> : <p></p>}
              
                />
         
             

              <Route 
                path="/suche" 
                element={ 
                    loggedIn ? (
                      <ResultGrid BuchKram={this.state.BuchKram} />
                    ) : (
                      <p></p>
                    )
                  }
              />  
        
              <Route 
                path="/detailanzeige/:isbn" 
                element={ 
                    loggedIn ? (
                      <ProductDetails BuchKram={this.state.BuchKram} />
                    ) : (
                      <p></p>
                    )
                  }
              />      
                      

              <Route
                path="/merkliste"
                element={loggedIn ? <Merkliste BuchKram={this.state.BuchKram} /> : <p></p>}
             
              />


<Route
                path="/warenkorb"
                element={loggedIn ? <Warenkorb BuchKram={this.state.BuchKram} /> : <p></p>}
             
              />
            
            </>
         



        </Routes>

        <Footer />
      </div>
    </Router>
  );
}
}


export default App;

