import React, { Component } from 'react';
import {Link} from 'react-router-dom';


class Warenkorb extends Component {

    state = { } 




    render() {
        
      
        const { BuchKram } = this.props;

        return  <div className="contentContainer">
                   

                    <div className='warenkorbContainer'>
                            <h3>Warenkorb</h3>    
                            <div className='warenkorbResultsRow  warenkorbResultsHead' >
                                    <div className='wwarenkorbResultsCell'>
                                        
                                    </div>
                                    <div className='warenkorbResultsCell'>
                                  
                                    </div>
                                    <div className='warenkorbResultsCell'>
                                        
                                    </div>
                                    <div className='warenkorbResultsCell'>
                                    Bestellzeichen
                                    </div>
                                    <div className='warenkorbResultsCell'>
                                        Preis
                                    </div>
                                    <div className='warenkorbResultsCell'>
                              
                                    </div>



                            </div>

                             {BuchKram.map( item => (

                               <div className='warenkorbResultsRow' key={item.id}>
                                    <div className='warenkorbResultsCell tableCellCb'>
                                        <input className='mobileCB' type='checkbox'/>
                                         
                                    </div>
                                    <div className='warenkorbResultsCell coverMobile'>
                                        <img src={item.thumb} width={250} />
                                    </div>
                             
                                    <div className='warenkorbResultsCell'>
                                        
                                        <div className='mobileLabel'>
                                            Titel:
                                        </div>
                                        <b>{item.titel}</b>
                                        <br/>
                                        <div className='mobileLabel'>
                                            Untertitel:
                                        </div>
                                        <i>{item.untertitel}</i>
                                        <br/>
                                        <div className='mobileLabel'>
                                            Autor:
                                        </div>
                                        {item.autor}
                                        <br/>
                                        <div className='mobileLabel'>
                                            Verlag:
                                        </div>
                                        {item.verlag}
                                        <br/>
                                        <div className='mobileLabel'>
                                            ISBN:
                                        </div>
                                         {item.isbn}
                                    
                                        <br/>
                                        <div className='mobileLabel'>
                                            Auflage:
                                        </div>
                                        {item.auflage}        

                                        <div className='textstyleMobile'>
                                            Einband/Art: {item.einband}/{item.art}   
                                        </div>

                                        <div className='textstyleMobile'>
                                            Themen: {item.themen}  
                                        </div>

                                       {/* <div className='textstyleMobile'>
                                            <button className="btn btn-primary umbButton" onClick={this.handleButtonClick}>Weitere Details anzeigen</button>
                                                        {showDiv && (
                                                            <div style={{maxWidth: '450px'}} >
                                                               
                                                                <p>Wird noch korrekt implementiert und wird für alle Zeilen einzeln steuerbar werden - das Laden der Inhalte wird erst mit dem Klick des
                                                                    Buttons ausgelöst.
                                                                </p>
                                                            </div>
                                                            )}
                                                                                    
                                        </div> */}
                                                   
                                        <div className='textstyleMobile' style={{display: "none"}} >
                                            Kurztext: {item.kurztext}  
                                        </div>

                                    </div>

                                    <div className='warenkorbResultsCell'>
                                       
                                        <input name="bestellzeichen" id="bestellzeichen" type="textarea"/>  
                                    </div>    


                                    <div className='warenkorbResultsCell'>
                                        <div className='mobileLabel'>
                                            Preis:
                                        </div>
                                        {item.preis} {item.uvp}
                                        <br/>
                                        <div className='textstyleMobile'>
                                            <div className={item.meldetext}>
                                                {item.meldetext}
                                            </div>
                                        </div>    
                                    </div>
                         
                                    
                                    <div className='warenkorbResultsCell'>
                                        <button className="umbButtonIconDelete"> 
                                            <span className="iconDeletetHover"></span>
                                        </button> 
                                        <button className="umbButtonIconList"> 
                                            <span className="iconListHover"></span>
                                        </button> 
                                       
                                        <Link to={`/detailanzeige/${item.isbn}`}>
                                                <button className="umbButtonIconInfo">
                                                    <span className="iconInfoHover">
                                                    </span>
                                                </button>    
                                        </Link>
                                        <br />    
                               
                                    </div>
                                    
                                    
                                </div>
                              
                            ))}


                    </div>


                </div>;

                




    }
}
 
export default Warenkorb;