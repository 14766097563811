import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class Header extends Component {
    state = {  } 
    render() { 
        const { loggedIn, onLogin, onLogout } = this.props;

        return (
            <div className="headerContainer">
                <div className="headerLeft">
                    <div className='logo'>
                        <img src='/assets/img/umbreit-logo.svg'/>
                        
                    </div>
                    <div className='firmenName'>
                
                        Demo-Pressewebshop
                    </div>
                </div>


                <div className="headerRight">
                    {loggedIn ? (
                        <div className="loginLogout">
                            <Link className="nav-link loginOutRight" onClick={onLogout}>
                                <span className="iconNavLogout"> 
                                    <img src="/assets/img/icons/logout_32_umb.png" className="iconNavLogoutHover"></img>
                                </span> 
                            </Link>
                        </div> 
                    ):(
                        <div className="loginLogout">
                            <Link className="nav-link loginOutRight" to="/suche" onClick={onLogin}>
                                <span className="iconNavLogin"> 
                                    <img src="/assets/img/icons/login_32_umb.png" className="iconNavLoginHover"></img>
                                </span> 
                            </Link>
                        </div>    
                    )}   
                </div>


            </div>
        );
    }
}
 
export default Header;