import React, { Component } from 'react'; //imrc Enter



// class zu className # zu /

// styleS anstelle von style

class Cards extends Component {
    state = {  } 
    render() { 

        const  {loggedIn} = this.props;

        return  <div className="contentContainer">
                    <div className="card">
                        <div className="cardImgContainer">
                            <img className="card-img-top image-height" src={this.props.image} alt="" />
                        </div>    
                        <div className="card-body">
                            <div className="cardHeadlineContainer">
                                <h5 className="card-title">{this.props.title}</h5>
                                <p className="card-text">{this.props.description}</p>
                            </div>
                            <div className="cardDetailContainer">   
                                <p>
                                    {this.props.preview}
                                </p>
                            </div>     
                        </div>

                    </div>
                </div>
                
    }
}
 
export default Cards;

// mit Button
/*
            <div className="card" styles={{width: '18rem'}}>             
                    <img className="card-img-top image-height" src={"/assets/img/" + this.props.image} alt="" />
                    <div className="card-body">
                        <h5 className="card-title">{this.props.title}</h5>
                        <p className="card-text">{this.props.description}</p>
                        <button onClick={this.props.onAdd} className="btn btn-primary">Zum Warenkorb</button>
                    </div>
                </div>
                */