import React, {Component} from 'react';

class Dashboard extends Component {
    state = {  } 
    render() { 

        const {loggedIn} = this.props;

        return (
              <div className="contentContainer">
                <h3>Mehr in Kürze</h3>
              </div>  



            /*<div>
                <h2>Dashboard</h2>
                {loggedIn ? (
                    <p>LoggedIn-Area</p>
                ) : (
                    <p>Access Denied</p>
                )}
            </div>*/


        );
    }
}
 
export default Dashboard;