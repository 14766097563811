import React, { Component } from 'react';
import {Link} from 'react-router-dom';

// wegen Snippets-Installation: cc und Enter legt direkt eine Klasse an
/*class Navbar extends Component {
    state = {  } 
    render() { 
        return <nav className="navbar bg-light">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/">Navbar</a>    
                    </div>"
                </nav>;
    }
}*/


class Navbar extends Component {
    state = {  } 
    render() { 

        const { loggedIn, onLogin, onLogout } = this.props;

        return <nav className="navbar navbar-expand-lg pgnav">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    
                    <div className="navContainer">
                        <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                                    
                            
                                {loggedIn ? (
                                    <>
                                         <li className="nav-item" >
                                            <Link className="nav-link" to ="/suche">
                                            
                                                    <span className="iconNavSearch"> 
                                                        <img src="/assets/img/icons/search_32_w.png" className="iconNavSearchHover"></img>
                                                    </span> 
                                            </Link>
                                        </li>
                                        
                                        
                                        <li className="nav-item" >
                                            <Link className="nav-link" to ="/warenkorb">
                                            
                                                    <span className="iconNavWK"> 
                                                        <img src="/assets/img/icons/cart_32_w.png" className="iconNavWKHover"></img>
                                                    </span> 
                                            </Link>
                                        </li>
                                     
                                        <li className="nav-item" >
                                            <Link className="nav-link" to ="/merkliste">
                                            
                                                    <span className="iconNavList"> 
                                                        <img src="/assets/img/icons/merkliste_32_w.png" className="iconNavListHover"></img>
                                                    </span> 
                                            </Link>
                                        </li>

                                     

                                        <li className="nav-item" >
                                            <Link className="nav-link" to ="/dashboard">
                                            
                                                    <span className="iconNavHistory"> 
                                                        <img src="/assets/img/icons/history_32_w.png" className="iconNavHistoryHover"></img>
                                                    </span> 
                                            </Link>
                                        </li>


                                    
                                       

                                    </>
                                ) : (
                                    <>
                                    <li className="nav-item active">
                                        <a className="nav-link" href="/">Home</a>
                                    </li>
                                    <li className="nav-item" onClick={onLogin}>
                                        <Link className="nav-link" to ="/suche">Login</Link>
                                    </li>
                                    <li className="nav-item" >
                                           
                                    </li>



                                    </>
                                ) }    
                            </ul>
                    
                    
                        </div> 
                    </div>    
                </nav>
    }
}


 
export default Navbar;


