import React, { Component, useState } from 'react';
import {Link} from 'react-router-dom';


class ResultGrid extends Component {

    state = {
       
    };



    constructor(props) {
        super(props);
    
        this.state = {
          showDiv: false,
        };
      }
    
      handleButtonClick = () => {
        this.setState((prevState) => ({
          showDiv: !prevState.showDiv,
        }));
      };


    render() {
        
        const {mainDropdown, subDropdown} = this.state;
        const { showDiv } = this.state;
        const { BuchKram } = this.props;

        return  <div className="contentContainer">
                    <div className='bibSuche'>

                        <form id='suchFormular'>
                            <div className='formularBib'>
                                <div className='formularBibRow'>
                                    <div className='formularBibCell'>
                                        <label for='sucheTitel'>Titel/ISBN/Autor/Stichwort</label>
                                    </div>
                                    <div className='formularBibCell'>    
                                        <input className='suchEingabe' type='text' name='sucheTitel' id='sucheTitel' style={{width: '250px'}} placeholder='Titel/ISBN/Autor/Stichwort'/>
                                    </div>
                                    <button className="umbButtonIconSearch"> 
                                        <span className="iconSearchHover"></span>
                                    </button> 

                                </div>

                               
                                <div className='formularBibRow'>
                                    <div className='formularBibCell'>
                                        <label for='sucheThema'>Thema</label>
                                    </div>
                                    <div className='formularBibCell'>    
                                        <input className='suchEingabe' type='text' name='sucheThema' id='sucheThema' style={{width: '250px'}} placeholder='Thema'/>
                                    </div>
                                </div>
                               


                                <div className='formularBibRow'>
                                    <div className='formularBibCell'> 
                                        <label for='produktgruppeDropDown'>Warengruppe</label>
                                    </div>
                                    <div className='formularBibCell'> 
                                         <select name='produktgruppeDropDown' id='produktgruppeDropDown'>
                                            <option value='Nonselect'>Bitte wählen</option>
                                            <option value='Hardcover'>Hardcover</option>
                                            <option value='Kalender'>Kalender</option>
                                            <option value='Nonbook'>Nonbook</option>
                                         </select>
                                    </div>
                                </div>
            
                                <div className='formularBibRow'>
                                    <div className='formularBibCell'> 
                                        <label for='spracheDropDown'>Sprache</label>
                                    </div>
                                    <div className='formularBibCell'> 
                                         <select name='spracheDropDown' id='spracheDropDown'>
                                            <option value='Nonselect'>Bitte wählen</option>
                                            <option value='DEU'>Deutsch</option>
                                            <option value='EN'>Englisch</option>
                                            <option value='FRZ'>Französisch</option>
                                         </select>
                                    </div>
                                </div>

                                <div className='formularBibRow'>
                                    <div className='formularBibCell'> 
                                        <label for='sucheNurLieferbar'>nur lieferbare Titel</label>
                                    </div>
                                    <div className='formularBibCell'> 
                                         <input type='checkbox' name='sucheNurLieferbar' id='sucheNurLieferbar' checked/>
                                    </div>
                                </div>

                            </div>
                        </form>    
                    </div>


                    <hr className='hrUmb'/>


                    <div className='bibHeadMenu'>
                        <ul>
                            <li>Auswahl zum Warenkorb</li>
                            <li>Auswahl Merken</li>
                            <li>Sortieren nach Rang - Preis - Erscheinungsjahr</li>
                 
                        </ul>
                        
                    </div>

                    <hr className='hrUmb'/>
           

                    <div className='bibResults'>

                        <div className="bibResultsGrid"> 

                            {BuchKram.map( item => (
                                
                                
                                        
                                            <div className="bibResultsBox" key={item.id}  styles={{width: '15rem'}}>  
                                                    <div className="bibImgContainer">
                                                        <Link to={`/detailanzeige/${item.isbn}`}>
                                                            <img  styles={{height: '200px'}} src={item.cover} alt="" />
                                                        </Link>    
                                                    </div>     
                                                    <div className="card-body">
                                                        <div className='bibResultBoxTAP'>
                                                            <b>{item.titel}</b><br/>
                                                            {item.autor}<br/>
                                                            {item.verlag}<br/>
                                                           
                                                        </div>    
                                                        <br />
                                                        <div className='bibResultBoxOLA'>  
                                                            <div className={item.meldetext}>
                                                                 {item.meldetext}
                                                            </div>
                                                        </div>                      
                                                        <br/>
                                                        <div className='bibResultBoxPreis'>    
                                                            {item.preis}
                                                        </div>
                                                        <br></br>
                                                      
                                                       

                                                        <div className='bibResultBoxButtons'>
                                                            <Link to={`/detailanzeige/${item.isbn}`}>
                                                                <button className="umbButtonIconInfo">
                                                                    <span className="iconInfoHover">
                                                                    </span>
                                                                </button>    
                                                            </Link>
                                                                
                                                            <button className="umbButtonIconList"> 
                                                                <span className="iconListHover"></span>
                                                            </button> 
                                                            <button className="umbButtonIconCart" disabled={item.meldetext==='vergriffen'}> 
                                                                <span className="iconCartHover"></span>
                                                            </button> 

                                                                
                                                        </div>
                                                    </div>
                                                <hr className='hrUmb'/>   
                                            </div>
                                       
                                      
                                 
                                 
     
                            ))}
                               
                             </div>  


                    </div>


                </div>;

                




    }
}
 
export default ResultGrid;


